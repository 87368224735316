<style>
h2 {
    border-bottom: solid var(--border-size-m) var(--je-red);
    display: inline-block;
}
</style>

<section class="section-container">
    <div class="text-center mb-4 pt-6">
        <h2 class="title text-center pb-1 my-12 mb-1">Thank you!</h2>
        <p>I have received your message and will reach out to you shortly.</p>
        <p>Return to <a href="/">home</a>.</p>
    </div>

</section>